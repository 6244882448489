import React from 'react';
import AccountBadgesTable from './AccountBadgesTable';
import autoload from '../../helpers/autoload';

const AccountBadgesPanel = ({
  contact_id,
  convention_id,
  current_user_id,
  con_param,
}) => {
  return (
    <div className="panel">
      <div className="ribbon-top">
        <div className="panel_titlebar">Badges</div>
      </div>

      <AccountBadgesTable
        contactId={contact_id}
        conventionId={convention_id}
        currentUserId={current_user_id}
        conParam={con_param}
      />
    </div>
  );
};

export default autoload(AccountBadgesPanel, 'account-badges-panel');
