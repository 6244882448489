import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PurchasedBadgesTableRow from './PurchasedBadgesTableRow';

const PurchasedBadgesTable = ({ purchasedById, conventionId, conParam }) => {
  const [purchasedBadges, setPurchasedBadges] = useState([]);

  useEffect(() => {
    const queryString = `?convention_id=${conventionId}`;
    const path = `/api/v1/contacts/${purchasedById}/purchased_badges${queryString}`;

    axios
      .get(path)
      .then(response => {
        setPurchasedBadges(response.data.data.purchased_badges);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <table className="records">
      <thead>
        <tr>
          <th>Badge Type</th>
          <th>Allocation Used</th>
          <th>Recipient</th>
          <th>Badge Status</th>
        </tr>
      </thead>
      <tbody>
        {purchasedBadges.map(purchasedBadge => (
          <PurchasedBadgesTableRow
            key={purchasedBadge.id}
            purchasedBadge={purchasedBadge}
            conParam={conParam}
          />
        ))}
      </tbody>
    </table>
  );
};

export default PurchasedBadgesTable;
