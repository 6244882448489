import React from 'react';
import { startCase } from 'lodash';
import { badgeLostOrReturned, rowStyle } from './helpers/statusHelpers';

const PaymentMethodTableRow = ({ purchasedBadge, conParam }) => {
  return (
    <tr>
      <td style={rowStyle(badgeLostOrReturned(purchasedBadge.status))}>
        {purchasedBadge.badge_name}
      </td>
      <td style={rowStyle(badgeLostOrReturned(purchasedBadge.status))}>
        {purchasedBadge.badge_allocation_id ? 'Yes' : 'No'}
      </td>
      <td style={rowStyle(badgeLostOrReturned(purchasedBadge.status))}>
        <a href={`/acct/${purchasedBadge.contact.id}/badges/${conParam}`}>
          {purchasedBadge.contact.name}
        </a>
      </td>
      <td style={rowStyle(badgeLostOrReturned(purchasedBadge.status))}>
        {purchasedBadge.status === 'deleted'
          ? 'Returned'
          : startCase(purchasedBadge.status)}
      </td>
    </tr>
  );
};

export default PaymentMethodTableRow;
