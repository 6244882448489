import React from 'react';
import BadgeAllocationsTable from './BadgeAllocationsTable';
import autoload from '../../helpers/autoload';

const BadgeAllocationsPanel = ({ contact_id, convention_id, title, actionsVisible = true }) => {
  return (
    <div className="panel">
      <div className="ribbon-top">
        <div className="panel_titlebar">{title}</div>
      </div>

      <BadgeAllocationsTable
        contactId={contact_id}
        conventionId={convention_id}
        actionsVisible={actionsVisible}
      />
    </div>
  );
};

export default autoload(BadgeAllocationsPanel, 'badge-allocations-panel');
