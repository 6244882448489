import React from 'react';

const BadgeAllocationsTableRow = ({ badgeAllocation, actionsVisible }) => {
  const numberToCurrency = amount => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const numberToPercentage = (value, precision) =>
    `${Number(value).toFixed(precision)}%`;

  return (
    <tr>
      <td>{badgeAllocation.group_name}</td>
      <td>{badgeAllocation.variant_name}</td>
      <td style={{ textAlign: 'right' }}>
        {badgeAllocation.percent_discount
          ? numberToPercentage(badgeAllocation.discount, 2)
          : numberToCurrency(badgeAllocation.discount)}
      </td>
      <td style={{ textAlign: 'right' }}>{badgeAllocation.quantity}</td>
      <td style={{ textAlign: 'right' }}>{badgeAllocation.quantity_used}</td>
      {actionsVisible && (
        <>
          <td>
            <a href={`/badge_allocations/${badgeAllocation.id}`}>Show</a>
          </td>
          <td>
            <a href={`/badge_allocations/${badgeAllocation.id}/edit`}>Edit</a>
          </td>
          {badgeAllocation.quantity_used == 0 && (
            <td>
              <a
                data-confirm="Are you sure you want to destroy this Badge Allocation?"
                rel="nofollow"
                data-method="delete"
                href={`/badge_allocations/${badgeAllocation.id}`}
              >
                Destroy
              </a>
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default BadgeAllocationsTableRow;
