import React from 'react';
import PurchasedBadgesTable from './PurchasedBadgesTable';
import autoload from '../../helpers/autoload';

const PurchasedBadgesPanel = ({
  purchasing_contact_id,
  convention_id,
  con_param,
}) => {
  return (
    <div className="panel">
      <div className="ribbon-top">
        <div className="panel_titlebar">Badges Purchased</div>
      </div>

      <PurchasedBadgesTable
        purchasedById={purchasing_contact_id}
        conventionId={convention_id}
        conParam={con_param}
      />
    </div>
  );
};

export default autoload(PurchasedBadgesPanel, 'purchased-badges-panel');
