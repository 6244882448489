import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BadgeAllocationsTableRow from './BadgeAllocationsTableRow';

const BadgeAllocationsTable = ({ contactId, conventionId, actionsVisible }) => {
  const [badgeAllocations, setBadgeAllocations] = useState([]);

  useEffect(() => {
    const queryString = `?convention_id=${conventionId}`;
    const path = `/api/v1/contacts/${contactId}/badge_allocations${queryString}`;

    axios
      .get(path)
      .then(response => {
        setBadgeAllocations(response.data.data.badge_allocations);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <table className="records">
      <thead>
        <tr>
          <th>Group Name</th>
          <th>Badge Type</th>
          <th style={{ textAlign: 'right' }}>Discount</th>
          <th style={{ textAlign: 'right' }}>Quantity</th>
          <th style={{ textAlign: 'right' }}>Used</th>
          <th>{actionsVisible && 'Actions'}</th>
        </tr>
      </thead>
      <tbody>
        {badgeAllocations.map(badgeAllocation => (
          <BadgeAllocationsTableRow
            key={badgeAllocation.id}
            badgeAllocation={badgeAllocation}
            actionsVisible={actionsVisible}
          />
        ))}
      </tbody>
    </table>
  );
};

export default BadgeAllocationsTable;
