import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountBadgesTableRow from './AccountBadgesTableRow';

const AccountBadgesTable = ({
  contactId,
  conventionId,
  currentUserId,
  conParam,
}) => {
  const [accountBadges, setAccountBadges] = useState([]);
  const [viewBadgesInfo, setViewBadgesInfo] = useState([]);

  const handleBadgeInfoView = badgeId => {
    setViewBadgesInfo(prev =>
      prev.includes(badgeId)
        ? prev.filter(id => id !== badgeId)
        : [...prev, badgeId],
    );
  };

  useEffect(() => {
    const queryString = `?convention_id=${conventionId}`;
    const path = `/api/v1/contacts/${contactId}/badges${queryString}`;

    axios
      .get(path)
      .then(response => {
        setAccountBadges(response.data.data.badges);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <table className="records">
      <thead>
        <tr>
          <th>Badge Type</th>
          <th>Allocation Used</th>
          <th>Badge Status</th>
          <th>Purchase Date</th>
          <th>Return Date</th>
          <th>Delivery Type</th>
          <th>Messages</th>
        </tr>
      </thead>
      <tbody>
        {accountBadges.map(
          accountBadge =>
            accountBadge.badge_type !== 'ffp' && (
              <AccountBadgesTableRow
                key={accountBadge.id}
                accountBadge={accountBadge}
                viewBadgesInfo={viewBadgesInfo}
                handleBadgeInfoView={handleBadgeInfoView}
                currentUserId={currentUserId}
                conParam={conParam}
              />
            ),
        )}
      </tbody>
    </table>
  );
};

export default AccountBadgesTable;
