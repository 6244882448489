import React from 'react';
import { startCase } from 'lodash';
import moment from 'moment';
import { badgeLostOrReturned, rowStyle } from './helpers/statusHelpers';

const AccountBadgesTableRow = ({
  accountBadge,
  viewBadgesInfo,
  handleBadgeInfoView,
  currentUserId,
  conParam,
}) => {
  const userBadgeText =
    accountBadge.contact.id === currentUserId
      ? `You ${
          badgeLostOrReturned(accountBadge.status) ? 'do not' : ''
        } have a ${accountBadge.badge_name} badge`
      : `${accountBadge.contact.name} ${
          badgeLostOrReturned(accountBadge.status) ? 'does not' : ''
        } has a ${accountBadge.badge_name} badge`;

  return (
    <>
      <tr>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          <b>{userBadgeText}</b>
        </td>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          {accountBadge.badge_allocation_id ? 'Yes' : 'No'}
        </td>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          {accountBadge.status === 'deleted'
            ? 'Returned'
            : startCase(accountBadge.status)}
        </td>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          {accountBadge.sold_at &&
            moment(accountBadge.sold_at).format('MM/DD/YYYY')}
        </td>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          {accountBadge.refunded_at &&
            moment(accountBadge.refunded_at).format('MM/DD/YYYY')}
        </td>
        <td style={rowStyle(badgeLostOrReturned(accountBadge.status))}>
          {accountBadge.delivery_method === 'Special'
            ? 'See Badge Info'
            : accountBadge.delivery_method}
        </td>
        <td>
          {!badgeLostOrReturned(accountBadge.status) && (
            <a onClick={() => handleBadgeInfoView(accountBadge.id)}>
              Badge Information
            </a>
          )}
        </td>
      </tr>
      {accountBadge.purchasing_contact.id !== accountBadge.contact.id && (
        <tr>
          <td colSpan={7}>
            <p>
              <b>
                Purchased By:{' '}
                <a
                  href={`/acct/${accountBadge.purchasing_contact.id}/badges/${conParam}`}
                >
                  {accountBadge.purchasing_contact.name}
                </a>
              </b>
            </p>
          </td>
        </tr>
      )}
      {viewBadgesInfo.includes(accountBadge.id) && (
        <tr>
          <td
            colSpan={7}
            dangerouslySetInnerHTML={{ __html: accountBadge.badge_info }}
          ></td>
        </tr>
      )}
    </>
  );
};

export default AccountBadgesTableRow;
